.App {
  height: 100vh;
  text-align: center;
  font-size: 1.5vw;
}

:root {
  --off-white: #313131;
  display:inline;
  
}

.sneakpeeks{
  max-width:450px;
  max-height:450px;
}

.sneakpeeksCol{
  margin-bottom: 40px !important;
  max-width:500 !important;
  max-height:500 !important;
}

.mediaContainer{
  border-style: solid;
  border-color: #353535;
  border-radius: 15px;
  width: 100%;
}

.NFT_Carousel{
  max-width:500px !important;
  max-height:500px !important;
}

.introContainer{
  width: 100% !important;
  min-height: 100% !important;
  background-image:  url('components/containers/static/media/farm.jpg') ;
  margin-top: 80px;
  margin-bottom: 80px;
  background-repeat:repeat-x;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}


h1, h2, h3, h4, h6{
  color: rgb(0, 0, 0);
  padding-top: 40px;
  text-shadow: 0 0 3px #000000, 0 0 5px #ffffff;
  
}

p{
  color: rgb(255, 255, 255);
  text-shadow: -1px 1px 0 #000,
                          1px 1px 0 #000,
                         1px -1px 0 #000,
                        -1px -1px 0 #000;
}

.logo-item{
  color: #6c7179 !important;
}

.logo-item:hover{
  color: #ffffff !important;
}

.nav-logo{
  align-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.navbar {
  margin: auto;
  max-height: 200px;
  margin-bottom: 40px;

}

.nav-icon {
  position: relative;
}

.nav-item {
  
  margin-top: 80px;
  display: inline;
  margin-left: 1rem;
  color: rgb(182, 182, 182);
}

.nav-item:hover {
  color: rgb(241, 241, 241);
  cursor: pointer;
}

.nav-item > .active {
  color: black;
  border-bottom: 1px solid #333;
}

.section-dark {
  background: #333;
  color: rgb(54, 54, 54);
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.roadmap-container {
  margin-bottom: 20px;
  border-style: solid;
  border-color: #353535;
  border-radius: 15px;
  height: auto !important;
  background-image:  url('components/containers/static/media/farm2.jpg') ;
  background-repeat:repeat-x;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  border-top-left-radius: 20px;
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.sub-text {
  font-size: 25px;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.footer-container {
  max-width: 100%;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.right-side{
  position: absolute;
  left: 250px;
}

.left-side{
  position: absolute;
  right: 250px;
}

.roadCard{
  margin: auto;
  flex: 50%;
  background-color: #353535 !Important;
  color: white;
  border-radius: 350px !important;
  margin-bottom: -100px ; 
  max-width: 600px;
}

.card-body{
  margin: auto;
  height: 50% ;
  margin-bottom: 40px ;
}

.list-group-item{
  margin: auto;
  width: 60% !important;
  height: 40% !important;

}


.card-text {
  border-radius: 15px 50px 30px;
  margin-top: 30px !Important;
}


.teamCard{
  margin: auto;
  background-color: #353535 !Important;
  color: white;
  width: 288px !important;
  height: 390px !important;
  margin-bottom: 50px;
  align-items: center;
  align-content: center !important;
 
}



.separator-container {
  background-image: url("components/containers/static/media/anegg.png");
  background-repeat: repeat-x;
  height: 65px;
  margin: 64px -120px;
  padding-left: 120px;
  padding-right: 120px;
  width: 100%;
  max-width: 100% !important;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 70px;
  background: rgb(116, 113, 107);
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}

.back-to-top:hover {
  background: rgb(0, 0, 0);
}
