h5{
  color: rgb(255, 255, 255);
  
}

.right-side2{
    position: relative;
    left: 60px;
  }
  
  .left-side2{
    position: relative;
    right: 60px;
  }
  
  .roadCard2{
    margin: auto;
    flex: 50%;
    background-color: #353535 !Important;
    border-radius: 350px !important;
    max-width: 200px !important;
    
    font-size: 1vw;
  }
  
  .card-body{
    margin: auto;
    flex: 50%;
    width: auto !important;
    height: 60% !important;
    margin-bottom: 20px !important;
  }

  